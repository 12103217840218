import React from "react"
import { graphql } from "gatsby";
import { BlogProps, BlogListProps } from "../components/blog/blog-types";
import BlogList from "../components/blog/blog-list";
import SEO from "../components/seo";
import Layout from "../components/layout";

const CategoryPosts = ({ data, pageContext, ...props }: BlogListProps) => {
    const title = pageContext.title.slice(0, 1).toUpperCase() + pageContext.title.slice(1);
    const pageTitle = `${title} (${data.allMarkdownRemark.nodes.length})`
    return (
        <>
            <Layout>
                <SEO
                    title={title}
                    description={`Articles for ${title}`}
                />
                <BlogList title={pageTitle} posts={data.allMarkdownRemark.nodes}/>
            </Layout>

        </>
    )
}

export default CategoryPosts


/**
 * Call like {category: "/<name>/"}
 */
export const categoryQuery = graphql`
    query BlogPostByCategory(
        $category: String!,
    ) {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(
            filter: {
                frontmatter: {
                    category: { regex: $category }
                },
                fileAbsolutePath: {
                    regex: "/\/blog\//"
                }
            },
            sort: { fields: [frontmatter___date], order: ASC }
        ) {
            nodes {
                excerpt(pruneLength: 160)
                fields {
                    slug
                }
                frontmatter {
                    title
                    date(formatString: "MMMM DD, YYYY")
                    description
                    author
                    quote
                    quoteAuthor
                    category
                    tags
                } 
            }
        }
    }`